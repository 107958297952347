import React,{useEffect,useState} from 'react'
import './Login.css'
import store from '../../../mobx/store';
import { autorun, runInAction } from 'mobx';
import axios from 'axios'
import {TeamOutlined,SafetyCertificateOutlined} from '@ant-design/icons';
import axiosurl from '../../../axiosurl/axiosurl'
//npm install react-transition-group过渡动画1/2
import { TransitionGroup, CSSTransition } from "react-transition-group";
import '../../../transitions/transitions.css'; // 存放过渡动画的样式
export default function Login() {
    const [isshow, setisshow] = useState(false);//控制登录页显示和隐藏
    const [emailinputvalue, setemailinputvalue] = useState('');//用于拿到用户邮箱输入框输入的值
    const [emailcodeinputvalue, setemailcodeinputvalue] = useState('');//用于拿到用户邮箱验证码输入框输入的值
    const [emailinputdefaulttext, setemailinputdefaulttext] = useState('请输入手机号');
    const [emailcodeinputdefaulttext, setemailcodeinputdefaulttext] = useState('请输入验证码');
    const [countdown, setcountdown] = useState(60);//倒计时
    const [countdownisshow, setcountdownisshow] = useState(false);//用于控制倒计时的显示的隐藏
    const [logincollectionshow, setlogincollectionshow] = useState(false);//控制提示盒子显示和隐藏
    const [logincollectiontext, setlogincollectiontext] = useState('');//控制盒子的显示内容
    const [logincollectionshowtime, setlogincollectionshowtime] = useState(null);//用于解决提示盒子定时器点击时就清除之前定时器
    const [userloginisshow, setuserloginisshow] = useState(false);//用于控制点击topbar后显示用户登录式样还是注册登录式样
    useEffect(() => {
        var unsubscribe=autorun(()=>{
            setisshow(store.loginisshow)
            setuserloginisshow(store.userisshow);
          })
        return () => {
            unsubscribe()
        };
    }, []);
    // 点了关闭按钮
    const removedown=()=>{
        store.loginisshowdown(false)
        //清空数据
        setemailinputvalue('')
        setemailcodeinputvalue('')
    }
    //拿到用户邮箱输入框输入值的处理事件
    const emailinputonchange=(value)=>{
        setemailinputvalue(value)
    }
    //拿到用户邮箱验证码输入框输入值的处理事件
    const emailcodeinputonchange=(value)=>{
        setemailcodeinputvalue(value)
          }
    // 点了发送邮箱验证码按钮
    // const sendemail=()=>{
    //     //效验邮箱格式
    //     const emailRegex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    //     if(emailRegex.test(emailinputvalue)==true && emailinputvalue != ''){
    //         //显示倒计时
    //      setcountdownisshow(true)
    //      let counttext=60
    //      let counttexttime=setInterval(() => {
    //          if(counttext==1){
    //              //关闭显示和隐藏定时器
    //              setcountdownisshow(false)
    //              clearInterval(counttexttime)
    //              setcountdown(60)
    //          }else{
    //              counttext=counttext-1
    //              setcountdown(counttext)
    //          }
    //      }, 1000);
    //        const params2 = new URLSearchParams();
    //         params2.append('email', emailinputvalue);
    //         // axios.defaults.headers["Authorization"] = localStorage.getItem('token');
    //         axios.post('http://127.0.0.1:3001/api/send-emailcode',params2,
    //      )
    //      .then(res => {
    //         if(logincollectionshowtime){
    //             clearTimeout(logincollectionshowtime)
    //         }
    //       collectionto(res.data.message)
    //      })
    //     }else if(emailinputvalue == ''){
    //         collectionto('请输入邮箱号')
    //     }else{
    //         collectionto('邮箱格式不正确')
    //     }
    // }
    // 点了登录按钮
//     const tologindown=()=>{
//         //效验邮箱格式
//         const emailRegex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
//         //效验验证码格式
//         const regex = /^\d{6}$/;
//         if(emailRegex.test(emailinputvalue)==true && regex.test(emailcodeinputvalue)==true){
//               const params2 = new URLSearchParams();
//               params2.append('email', emailinputvalue);
//               params2.append('code', emailcodeinputvalue);
//               // axios.defaults.headers["Authorization"] = localStorage.getItem('token');
//               axios.post('http://127.0.0.1:3001/api/verify-code',params2,
//            )
//            .then(res => {
//              console.log(res.data);
//              if(res.data.message=='账号登录成功'){
//                 //浏览器存储token和用户
//                 localStorage.setItem('aiwebuser',res.data.user)
//                 localStorage.setItem('aiwebtoken',res.data.token)
//                 localStorage.setItem('aiwebmemberdate',res.data.memberdate)
//                 localStorage.setItem('aiwebmemberintegral',res.data.memberintegral)
//                 localStorage.setItem('aiwebuserphoto',res.data.userphoto)
//                 store.userupdate({
//                     aiwebuser:res.data.user,
//                     aiwebtoken:res.data.token,
//                     aiwebmemberdate:res.data.memberdate,
//                     aiwebmemberintegral:res.data.memberintegral,
//                     aiwebuserphoto:res.data.userphoto,
//                 })
//                 store.userisshowdown(2)
//                 // //读取token
//                 // const aiwebtoken=localStorage.getItem('aiwebtoken')
//                 // console.log(aiwebtoken);
//                 //关闭登录页面
//                 store.loginisshowdown(false)
//                 //清空数据
//                 setemailinputvalue('')
//                 setemailcodeinputvalue('')
//              }else if(res.data.message=='验证码失效'){
//                 collectionto('请重新获取验证码')
//              }else if(res.data.message=='验证码错误'){
//                 collectionto('验证码填写错误')
//              }
//            })
//         }else if(emailinputvalue=='' && emailcodeinputvalue==''){
//             collectionto('请填邮箱和验证码')
//         }else if(emailcodeinputvalue==''){
//             collectionto('请填写验证码')
//         }else if(emailinputvalue==''){
//             collectionto('请填写邮箱')
//         }else if(emailRegex.test(emailinputvalue)==false && regex.test(emailcodeinputvalue)==true){
//             collectionto('邮箱格式有误')
//         }else if(emailRegex.test(emailinputvalue)==true && regex.test(emailcodeinputvalue)==false){
//             collectionto('验证码格式有误')
//         }else if(emailRegex.test(emailinputvalue)==false && regex.test(emailcodeinputvalue)==false){
//             collectionto('您填写内容有误')
//         }
//  }
     // 点了发送手机验证码按钮
     const sendphone=()=>{
        //效验国内手机号码格式
        const emailRegex = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if(emailRegex.test(emailinputvalue)==true && emailinputvalue != ''){
            //显示倒计时
         setcountdownisshow(true)
         let counttext=60
         let counttexttime=setInterval(() => {
             if(counttext==1){
                 //关闭显示和隐藏定时器
                 setcountdownisshow(false)
                 clearInterval(counttexttime)
                 setcountdown(60)
             }else{
                 counttext=counttext-1
                 setcountdown(counttext)
             }
         }, 1000);
           const params2 = new URLSearchParams();
            params2.append('phone', emailinputvalue);
            params2.append('t', new Date().getTime());
            // axios.defaults.headers["Authorization"] = localStorage.getItem('token');
            axios.post(`${axiosurl}/api/send-phonecode`,params2,
         )
         .then(res => {
            if(logincollectionshowtime){
                clearTimeout(logincollectionshowtime)
            }
            collectionto(res.data.message)
         }).catch(error => {
            if(error.message=='Network Error'){
                collectionto('网络问题');
              }else if(error.message=='Request failed with status code 429'){
                collectionto('当天发送过多');
              }else{
                collectionto('其他问题');
              }
          })
        }else if(emailinputvalue == ''){
            collectionto('请输入手机号')
        }else{
            collectionto('手机格式不正确')
        }
    }
    // 点了登录按钮
    const tophonelogindown=()=>{
        //效验国内手机号码格式
        const emailRegex = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        //效验验证码格式
        const regex = /^\d{6}$/;
        if(emailRegex.test(emailinputvalue)==true && regex.test(emailcodeinputvalue)==true){
              const params2 = new URLSearchParams();
              params2.append('email', emailinputvalue);
              params2.append('code', emailcodeinputvalue);
              // axios.defaults.headers["Authorization"] = localStorage.getItem('token');
              axios.post(`${axiosurl}/api/verify-phonecode`,params2,
           )
           .then(res => {
             console.log(res.data);
             if(res.data.message=='账号登录成功'){
                //浏览器存储token和用户
                localStorage.setItem('aiwebuser',res.data.user)
                localStorage.setItem('aiwebtoken',res.data.token)
                localStorage.setItem('aiwebmemberdate',res.data.memberdate)
                localStorage.setItem('aiwebmemberintegral',res.data.memberintegral)
                localStorage.setItem('aiwebuserphoto',res.data.userphoto)
                store.userupdate({
                    aiwebuser:res.data.user,
                    aiwebtoken:res.data.token,
                    aiwebmemberdate:res.data.memberdate,
                    aiwebmemberintegral:res.data.memberintegral,
                    aiwebuserphoto:res.data.userphoto,
                })
                store.userisshowdown(2)
                // //读取token
                // const aiwebtoken=localStorage.getItem('aiwebtoken')
                // console.log(aiwebtoken);
                //关闭登录页面
                store.loginisshowdown(false)
                //清空数据
                setemailinputvalue('')
                setemailcodeinputvalue('')
             }else if(res.data.message=='验证码失效'){
                collectionto('请重新获取验证码')
             }else if(res.data.message=='验证码错误'){
                collectionto('验证码填写错误')
             }else if(res.data.message=='该账号已禁用'){
                collectionto('Sorry-该账号已封禁')
             }
           })
        }else if(emailinputvalue=='' && emailcodeinputvalue==''){
            collectionto('请填邮箱和验证码')
        }else if(emailcodeinputvalue==''){
            collectionto('请填写验证码')
        }else if(emailinputvalue==''){
            collectionto('请填写邮箱')
        }else if(emailRegex.test(emailinputvalue)==false && regex.test(emailcodeinputvalue)==true){
            collectionto('邮箱格式有误')
        }else if(emailRegex.test(emailinputvalue)==true && regex.test(emailcodeinputvalue)==false){
            collectionto('验证码格式有误')
        }else if(emailRegex.test(emailinputvalue)==false && regex.test(emailcodeinputvalue)==false){
            collectionto('您填写内容有误')
        }
 }
 //提示盒子的内容显示3秒
 const collectionto=(text)=>{
    if(logincollectionshowtime){
        clearTimeout(logincollectionshowtime)
    }
    setlogincollectiontext(text)
    setlogincollectionshow(true)
    let atime= setTimeout(() => {
        setlogincollectionshow(false)
    }, 3000);
    setlogincollectionshowtime(atime)
 }
 const UserAgreementdown=()=>{
    window.open('/UserAgreement.html', '_blank');
 }
 const UserPrivacyPolicydown=()=>{
    window.open('/UserPrivacyPolicy.html', '_blank');
 }
  return (
    <div style={{
        display: userloginisshow==1 ? 'block' : 'none',
      }}>
        {/* 过渡动画1/2 */}
        <CSSTransition
        in={isshow}              // 控制组件是否显示
        timeout={300}          // 动画时长
        classNames="fade"      // CSS 类名前缀
        unmountOnExit          // 退出时从 DOM 中移除
      >
        <div className='login'>
            {/* 提示盒子 */}
            {logincollectionshow?
            <div className='logintishihe'>
               <span>{logincollectiontext}</span>
            </div>
            :null}
            {/* 登录主体 */}
            <div className='loginbox'>
                <div className='remove' onClick={removedown}></div>
                <span className='textone'>手机验证码登录</span>
                <span className='texttwo'>未注册过账号将自动创建</span>
                <div className='email'>
                    <div className='imgbox'>
                       <TeamOutlined style={{ fontSize: '20px'}}/>
                    </div>
                    <input autoComplete="off" value={emailinputvalue} onChange={(evt)=>emailinputonchange(evt.target.value)} type='text' placeholder={emailinputdefaulttext}></input>
                </div>
                <div className='emailcode'>
                    <div className='imgbox'>
                       <SafetyCertificateOutlined style={{ fontSize: '20px'}}/>
                    </div>
                    <input autoComplete="off" value={emailcodeinputvalue} onChange={(evt)=>emailcodeinputonchange(evt.target.value)} type='text' placeholder={emailcodeinputdefaulttext}></input>
                    {countdownisshow?
                    <div className='countdown'>
                        <span>{countdown}</span>
                    </div>
                    :
                    <div className='send' onClick={sendphone}>
                        <span>发送验证码</span>
                    </div>}
                </div>
                <div className='tologin' onClick={tophonelogindown}>
                    <span>登录</span>
                </div>
                <div className='agreement'>
                    <span>欢迎使用Saro AI,您登录即同意</span>
                    <span onClick={UserAgreementdown}>《用户协议》</span>
                    <span>和</span>
                    <span onClick={UserPrivacyPolicydown}>《隐私政策》</span>
                </div>
            </div>
        </div>
        </CSSTransition>
    </div>
  )
}
